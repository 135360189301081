import React from "react";

import './index.css';

const Modale = ({}:Modale) => {
    return (
        <div id="modale">
            <div id="modale-container">
                <div id="modale-content" />
            </div>
        </div>
    );
};

interface Modale {

};

export default Modale;